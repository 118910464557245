import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import Cookies from 'js-cookie';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
// import { SplitText } from 'gsap/SplitText';
import LazyLoad from "vanilla-lazyload";
import TweenMax from 'gsap';
import TimelineMax from 'gsap';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
import Swiper from 'swiper';
import { Navigation, EffectFade, Pagination, Autoplay, EffectCreative, EffectFlip } from 'swiper/modules';

// Swiper
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

// var scrollMonitor = require("scrollmonitor"); 

document.documentElement.classList.remove('no-js');

// Modules

// import { exampleModule } from './modules/example';
// import { lazyLoadModule } from './modules/lazyLoad';
// import { parallaxModule } from './modules/parallax';
// import { headingModule } from './modules/heading';
// import { paragraphModule } from './modules/paragraph';
// import { inviewModule } from './modules/inview';
// import { scrollToModule } from './modules/scrollTo';
// import { buttonModule } from './modules/button';
// import { titleModule } from './modules/title'

// Exports

// exampleModule();
// lazyLoadModule();
// parallaxModule();
// headingModule();
// paragraphModule();
// inviewModule();
// scrollToModule();
// buttonModule();
// titleModule();

// Global Exports

window.Cookies = Cookies;
window.Alpine = Alpine;
window.LazyLoad = LazyLoad;

window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;
window.ScrollToPlugin = ScrollToPlugin;
// window.DrawSVGPlugin = DrawSVGPlugin;
// window.scrollMonitor = scrollMonitor;
// window.SplitText = SplitText;
window.TweenMax = TweenMax;
window.TimelineMax = TimelineMax;
window.Swiper = Swiper;
Swiper.use([Autoplay, Pagination, Navigation, EffectFade, EffectCreative, EffectFlip]);

// Inits

Alpine.plugin(collapse);
Alpine.start();

